import React from "react";
import { Link } from "react-router-dom";
import RolesLayout from "../../layouts/RolesLayout/RolesLayout";
import { ROLES } from "../../Constant/Roles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { BASE_URL } from "../../config";
import { undefinedImage } from "../../Images";
import ImageUploadComponent from "../ImageUploadComponent";
import useFileUpload from "../../hooks/UseFileUpload";

const ProductCard = ({
  product,
  handleEdit,
  onDelete,
  onSubmit,
  category,
  loading,
  onEdit,
}) => {
  const titleRef = React.useRef(null);
  const descriptionRef = React.useRef(null);
  const [editable, setEditable] = React.useState(false);
  const { title, description, discount, poster, id } = product;
  const { data, message, progress, uploadFile } = useFileUpload(
    `/uploads`,
    "poster"
  );
  const image = `${BASE_URL.replace("/api", "")}${poster}`;

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-6 max-w-md">
      {discount && (
        <div className="bg-red-500 text-white text-sm py-1 px-3 rounded-full absolute top-4 left-4">
          قيمه الخصم {discount} ريال
        </div>
      )}
      <div className="relative">
        {!editable && (
          <img
            crossOrigin="true"
            src={image ?? undefinedImage}
            alt={title}
            loading="lazy"
            className="w-full h-40 object-contain rounded-lg"
          />
        )}
        {editable && (
          <ImageUploadComponent
            onChange={uploadFile}
            progress={progress}
            img={data?.poster}
            msg={message}
          />
        )}
        <h2
          className={`text-3xl font-semibold mt-4 ${
            editable ? "border-b border-gray-600" : ""
          } text-white`}
          ref={titleRef}
          contentEditable={editable}
          name="title"
        >
          {title}
        </h2>
      </div>
      <div className="mt-4">
        <h5 className="text-gray-400 text-lg">معلومات:</h5>
        <p
          className={`text-gray-300 text-lg line-clamp-2 mt-2 max-h-20 overflow-hidden ${
            editable ? "border-b border-gray-600" : ""
          }`}
          contentEditable={editable}
          name="description"
          ref={descriptionRef}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="flex justify-between items-center mt-6">
        <Link
          to={`/products/${id}` ?? "/"}
          className=" text-xl text-blue-400 hover:underline"
        >
          المزيد
        </Link>
        <RolesLayout roles={[ROLES.ADMIN]}>
          <div className="flex space-x-3  gap-4">
            <button
              onClick={() => setEditable((prev) => !prev)}
              disabled={loading}
              className="text-yellow-400 hover:text-yellow-500 focus:outline-none"
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
            <button
              disabled={loading}
              className="text-red-400 hover:text-red-500 focus:outline-none"
              onClick={() => onDelete(id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
            {editable && (
              <button
                disabled={loading}
                className="text-green-400 hover:text-green-500 focus:outline-none"
                onClick={() =>
                  onSubmit(id, {
                    description: descriptionRef.current.textContent,
                    title: titleRef.current.textContent,
                    poster: data?.poster ?? "",
                  })
                }
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            )}
          </div>
        </RolesLayout>
      </div>
    </div>
  );
};

export default React.memo(ProductCard);
