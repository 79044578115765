import React from "react";
import {Link} from "react-router-dom";

const Value = (props) => {
  return (
    <section>
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            <img
              alt={props.title}
              src={props.img}
              className="absolute inset-0 h-full w-full object-contain"
            />
          </div>

          <div className="lg:py-24">
            <h2 className="text-4xl font-bold sm:text-4xl">{props.title}</h2>

            <p className="mt-4 text-2xl text-gray-800">{props?.description}</p>

            <Link
              href="#"
              className="mt-8  inline-block rounded bg-color-secondary px-12 py-3 text-3xl font-medium text-black transition hover:bg-color-secondary/90 focus:outline-none focus:ring focus:ring-yellow-400"
            >
              طلب نسخة تجريبية
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Value);
