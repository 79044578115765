import React from "react";
import {Link} from "react-router-dom";
import {FaWhatsapp} from "react-icons/fa";
const WhatsappButton = () => {
  return (
    <Link
      to={
        "https://wa.me/966546811900?text=السلام عليكم اتصواصل معكم من موقعكم الآلكتروني بخصوص"
      }
      target="_blank"
      className="fixed text-9xl  p-4 h-[4rem] w-[4rem] hover:opacity-90 transition-all duration-150 flex items-center justify-center  bottom-[50%]  text-white left-[2rem]  rounded-full bg-green-400 z-[100]"
    >
      <FaWhatsapp />
    </Link>
  );
};

export default WhatsappButton;
